<template>
	<div class="content">
		<a-button class="editable-add-btn" @click="add">
			添加
		</a-button>
		<a-drawer title="Create a new account" :width="720" :visible="visible" :body-style="{ paddingBottom: '80px' }"
			@close="visible = false">
			<a-form :form="form" layout="vertical" hide-required-mark>
				<a-row :gutter="16">
					<a-col :span="12">
						<a-form-item label="姓名">
							<a-input v-decorator="[
	                  'real_name',
	                  {
	                    rules: [{ required: true, message: '姓名不能为空' }],
	                  },
	                ]" placeholder="请输入姓名" />
						</a-form-item>
					</a-col>
					<a-col :span="12">
						<a-form-item label="手机号">
							<a-input
								v-decorator="['phone',{rules: [{ required: true, message: '手机号不能为空' }],},]"
								placeholder="请输入手机号" />
						</a-form-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<a-col :span="24">
						<a-form-item label="详细地址">
							<a-textarea
								v-decorator="['detail',{rules: [{ required: true, message: '详细地址不能为空' }],},]"
								:rows="4" placeholder="请输入详细地址" />
						</a-form-item>
					</a-col>
				</a-row>
			</a-form>
			<div :style="{
	          position: 'absolute',
	          right: 0,
	          bottom: 0,
	          width: '100%',
	          borderTop: '1px solid #e9e9e9',
	          padding: '10px 16px',
	          background: '#fff',
	          textAlign: 'right',
	          zIndex: 1,
	        }">
				<a-button :style="{ marginRight: '8px' }" @click="visible = false;">
					取消
				</a-button>
				<a-button type="primary" @click="submit">
					提交
				</a-button>
			</div>
		</a-drawer>
		<a-table :pagination='false' bordered :data-source="dataSource" rowKey="id" :columns="columns">
			<template slot="is_default" slot-scope="text, record">
				<a-switch :default-checked="record.is_default?true:false" @change="onChange($event,record.id)" />
			</template>
			<template slot="operation" slot-scope="text, record">
				<a @click="edit(record.id)" style="margin-right: 10px;" href="javascript:;">编辑</a>
				<a-popconfirm v-if="dataSource.length" title="Sure to delete?" @confirm="() => onDelete(record.id)">
					<a href="javascript:;">删除</a>
				</a-popconfirm>
			</template>
		</a-table>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				current: {},
				type: 'add',
				form: this.$form.createForm(this),
				visible: false,
				dataSource: [],
				columns: [{
						title: '姓名',
						dataIndex: 'real_name',
					},
					{
						title: '手机号',
						dataIndex: 'phone',
					},
					{
						title: '地址',
						dataIndex: 'detail',
					},
					{
						title: '默认',
						dataIndex: 'is_default',
						scopedSlots: {
							customRender: 'is_default'
						},
					},
					{
						title: '操作',
						dataIndex: 'operation',
						scopedSlots: {
							customRender: 'operation'
						},
					},
				],
			};
		},
		mounted() {
			this.addressLst()
		},
		methods: {
			onChange(checked, id) {
				this.changeAddress({
					id,
					is_default: checked ? 1 : 0,
					type: 'default'
				})
				this.addressLst()
			},
			add() {
				this.visible = true;
				this.type = 'add';
				this.form.resetFields();
			},
			async addressLst() {
				this.dataSource = []
				setTimeout(async () => {
					let {
						result
					} = await this.$api.addressLst()
					this.dataSource = result
				}, 100)
			},
			edit(id) {
				this.type = 'edit';
				this.visible = true
				this.form.resetFields();
				this.current = this.dataSource[this.dataSource.findIndex(v => v.id == id)]
				setTimeout(() => {
					this.form.setFieldsValue({
						real_name: this.current.real_name,
						phone: this.current.phone,
						detail: this.current.detail
					})
				}, 100)
			},
			async changeAddress(params) {
				await this.$api.changeAddress(params)
			},
			submit() {
				this.form.validateFields((err, values) => {
					if (!err) {
						this.changeAddress({
							id: ['edit', 'delete', 'default'].includes(this.type) ? this.current.id : '',
							...values,
							type: this.type
						})
					}
				});
				this.visible = false
				this.addressLst()
			},
			onDelete(id) {
				this.type = 'delete';
				this.current.id = id;
				this.submit()
			},
		},
	};
</script>
<style lang="scss" scoped>
	.editable-cell {
		position: relative;
	}

	.editable-cell-input-wrapper,
	.editable-cell-text-wrapper {
		padding-right: 24px;
	}

	.editable-cell-text-wrapper {
		padding: 5px 24px 5px 5px;
	}

	.editable-cell-icon,
	.editable-cell-icon-check {
		position: absolute;
		right: 0;
		width: 20px;
		cursor: pointer;
	}

	.editable-cell-icon {
		line-height: 18px;
		display: none;
	}

	.editable-cell-icon-check {
		line-height: 28px;
	}

	.editable-cell:hover .editable-cell-icon {
		display: inline-block;
	}

	.editable-cell-icon:hover,
	.editable-cell-icon-check:hover {
		color: #108ee9;
	}

	.editable-add-btn {
		margin-bottom: 8px;
	}

	.content {
		margin-top: 20px;
	}
</style>
